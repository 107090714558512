import gql from 'graphql-tag';

export default gql`
mutation addReportMember($obj: AddReportMemberInput!) {
  addReportMember(object: $obj) {
    user_id
    role_id
    report_id
    user_already_in_db
  }
}
`;
