import gql from 'graphql-tag';

export default gql`
mutation deleteReportMember($obj: DeleteReportMemberInput!) {
  deleteReportMember(object: $obj) {
    report_id
    user_id
  }
}
`;
