import gql from 'graphql-tag';

export default gql`
query getReportDetails($reportId: Int!) {
  getReportDetails(object: { reportId: $reportId }) {
    report_id
    name
    description
    settings
    access
    items
    users
  }
}
`;
