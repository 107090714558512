import gql from 'graphql-tag';

export default gql`
  mutation insertReportItem($object: insertReportItemInput!) {
    insertReportItem(object: $object) {
      report_id
      folder_id
      project_id
    }
  }
`;
