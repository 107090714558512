import gql from 'graphql-tag';

export default gql`
mutation deleteReportItem($obj: DeleteReportItemInput!) {
  deleteReportItem(object: $obj) {
    report_id
    folder_id
    project_id
  }
}
`;
