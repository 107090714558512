<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0">{{ report.name }}</h3>
        <b-dropdown
          v-if="writeAccess"
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="openEditReportModal = true;">
            {{ $t('dropdowns.edit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="openDeleteReportModal = true;">
            {{ $t('dropdowns.delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <hr>
      </b-col>
      <modal-edit-report
        v-if="openEditReportModal"
        class="d-inline-block"
        :report="report"
        @edit-report="editReport"
        @cancel="openEditReportModal = false"
      ></modal-edit-report>
      <modal-delete-report
        v-if="openDeleteReportModal"
        class="d-inline-block"
        :report="report"
        @delete-report="deleteReport"
        @cancel="openDeleteReportModal = false"
      ></modal-delete-report>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <p class="mb-0">{{ report.description }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">{{ $t('reports.settings') }}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex">
        <label class="mr-2 mt-2">Schedule:</label>
        <div v-if="!writeAccess">
          <span style="position:relative;top:4px;">
            {{ dropdownText }}
          </span>
        </div>
        <div v-else>
          <b-dropdown menu-class="w-100" id="setSchedule" block :text="dropdownText">
            <b-dropdown-item
              v-for="option in scheduleOptions"
              :key="option.key"
              @click="setSchedule(option)"
            >
              {{ option.value }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.reportDescription.users') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
              icon="search"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
          <b-button
            v-if="writeAccess"
            @click="openSubscribeUserModal = true;"
            class="green-button-transparent border-0 ml-3"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('buttons.addMember') }}
          </b-button>
          <modal-subscribe-user-to-report
            v-if="openSubscribeUserModal"
            @add-user="addUser"
            @cancel="openSubscribeUserModal = false"
          ></modal-subscribe-user-to-report>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
          v-if="users"
          @sort-changed="sortChanged"
          @context-changed="sortChanged"
          id="userTable"
          ref="userTable"
          :fields="userFields"
          :items="users"
          :filter="filter"
          sort-icon-left
          show-empty
          borderless
          dark
          class="table-view users-table"
        >
          <template #empty="scope">
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noUsers') }}
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredUsers') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <b-avatar
              v-if="data.item.image !== null"
              size="32px"
              :src="data.item.image"
            ></b-avatar>
            <b-avatar v-else size="32px"></b-avatar>
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>
          <template #cell(dot)="data">
            <b-dropdown
              v-if="writeAccess"
              class="context-menu"
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
              right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
              </template>
              <b-dropdown-item
                @click="openUnsubscribeUser(data.item)">
                {{ $t('dropdowns.remove') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <modal-unsubscribe-user-from-report
          v-if="openUnsubscribeUserModal"
          class="d-inline-block"
          :user-to-be-deleted="userToBeDeleted"
          @deleteUser="deleteUser"
          @cancel="openUnsubscribeUserModal = false"
        ></modal-unsubscribe-user-from-report>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.reportDescription.items') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right">
          <modal-add-new-report-item
            v-if="writeAccess && user"
            class="d-inline-block"
            :user-id="userId"
            :items-from-d-b="items"
            @addItems="addItems"
          ></modal-add-new-report-item>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
          :fields="itemFields"
          :items="items"
          sort-icon-left
          show-empty
          borderless
          dark
          class="table-view items-table"
        >
          <template #empty="scope">
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noItems') }}
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredItems') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <div
              v-if="data.item.type === 'folder'"
              class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
            >
              <b-icon
                icon="folder"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
            <div
              v-if="data.item.type === 'project'"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                icon="files"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
            <div
              v-if="data.item.type === 'page'"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                icon="file"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
          </template>
          <template #cell(group)="data">
            <span :id="`tooltip-group-${data.item.id}`">
            {{ data.item.group_name | sliceStingPos(20) }}
            </span>
            <b-tooltip
              v-if="data.item.group_name.length > 20"
              :target="`tooltip-group-${data.item.id}`"
            >
              {{ data.item.group_name }}
            </b-tooltip>
          </template>
          <template #cell(path)="data">
            <span :id="`tooltip-path-${data.item.id}`">
            {{ data.item.path | sliceStingPos(25) }}
            </span>
            <b-tooltip
              v-if="data.item.path.length > 25"
              :target="`tooltip-path-${data.item.id}`"
            >
              {{ data.item.path }}
            </b-tooltip>
          </template>
          <template #cell(name)="data">
            <span :id="`tooltip-name-${data.item.id}`">
            {{ data.item.name | sliceStingPos(23) }}
            </span>
            <b-tooltip
              v-if="data.item.name.length > 23"
              :target="`tooltip-name-${data.item.id}`"
            >
              {{ data.item.name }}
            </b-tooltip>
          </template>
          <template #cell(dot)="data">
            <b-dropdown
              v-if="writeAccess"
              class="context-menu"
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
              right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
              </template>
              <b-dropdown-item
                @click="showRemoveItemModal(data.item)"
              >
                {{ $t('modals.deleteTeamItem.dropdown') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <modal-remove-item
          v-if="openRemoveItemModal"
          class="d-inline-block"
          :item="itemToBeRemoved"
          @remove-item="removeItem"
          @cancel="openRemoveItemModal = false"
        ></modal-remove-item>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11" v-if="!writeAccess">
        <div class="info-box px-4 py-2 text-center">
          {{ $t('reports.notAllowedToEdit') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AddReportMember from '@/graphQlQueries/mutations/addReportMember';
import DeleteReportMember from '@/graphQlQueries/mutations/deleteReportMember';
import InsertReportItem from '@/graphQlQueries/mutations/insertReportItem';
import DeleteReportItem from '@/graphQlQueries/mutations/deleteReportItem';
import GetReportDetails from '@/graphQlQueries/queries/getReportDetails';
import UpdateReport from '@/graphQlQueries/mutations/updateReport';
import DeleteReport from '@/graphQlQueries/mutations/deleteReport';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'PageSettingsReportsPageDescription',
  components: {
    ModalEditReport: () => import('@/components/modals/ModalEditReport.vue'),
    ModalDeleteReport: () => import('@/components/modals/ModalDeleteReport.vue'),
    ModalSubscribeUserToReport: () => import('@/components/modals/reports/ModalSubscribeUserToReport.vue'),
    ModalUnsubscribeUserFromReport: () => import('@/components/modals/reports/ModalUnsubscribeUserFromReport.vue'),
    ModalAddNewReportItem: () => import('@/components/modals/reports/ModalAddNewReportItem.vue'),
    ModalRemoveItem: () => import('@/components/modals/reports/ModalRemoveReportItem.vue'),
  },
  props: ['report', 'reportId', 'user'],
  data() {
    return {
      userFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('reports.table.name'),
          sortable: true,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'email',
          label: this.$t('reports.table.email'),
          sortable: true,
          width: 'auto',
          tdClass: 'email_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      itemFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('reports.table.name'),
          sortable: false,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'path',
          label: this.$t('reports.table.path'),
          sortable: false,
          width: 'auto',
          tdClass: 'path_column',
        },
        {
          key: 'group',
          label: this.$t('reports.table.group'),
          sortable: false,
          width: '150px',
          tdClass: 'group_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      scheduleOptions: [{ key: 'monthly', value: 'Monthly' }, { key: 'weekly', value: 'Weekly' }],
      schedule: null,
      dropdownText: null,
      users: [],
      items: [],
      filter: null,
      number: Math.floor(Math.random() * 1000),
      openEditReportModal: false,
      openDeleteReportModal: false,
      openSubscribeUserModal: false,
      openUnsubscribeUserModal: false,
      openRemoveItemModal: false,
      userToBeDeleted: null,
      itemToBeRemoved: null,
      accessLevel: null,
    };
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  apollo: {
    reportDetailData: {
      variables() {
        return {
          reportId: this.reportId,
        };
      },
      query: GetReportDetails,
      update(data) {
        if (data.getReportDetails) {
          console.log('report detail data', data.getReportDetails);
          const {
            users,
            items,
            access,
            settings,
          } = data.getReportDetails;
          this.accessLevel = access;
          this.users = this.buildTheUsers(users);
          this.items = this.buildTheItems(items);
          this.buildTheSettings(settings);
        }
      },
      skip() {
        return !this.reportId;
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    writeAccess() {
      if (this.user?.isSysAdmin) {
        return true;
      }
      switch (this.accessLevel) {
        case 'OWNER':
        case 'WRITE':
          return true;
        case 'READ':
          return false;
        default:
          return false;
      }
    },
    isSysAdmin() {
      return !!this.user?.sys_admin;
    },
    userId() {
      return this.user?.userId;
    },
  },
  methods: {
    sortChanged() {
      this.number++;
    },
    setSchedule(value) {
      console.log('setting schedule...', value, this.settings, this.report);
      this.dropdownText = value.value;
      if (this.settings) {
        this.settings.schedule = value.key;
      } else {
        this.settings = {
          schedule: value.key,
        };
      }
      // update the report here
      this.updateReport();
    },
    getImageUrl(imageObject) {
      let cloudImageUrl = null;
      if (imageObject?.url) {
        cloudImageUrl = this.$makeCloudImage(
          imageObject.url,
          {
            width: 32,
            height: 32,
          },
          imageObject,
          null,
          true,
        );
      }
      return (imageObject?.url)
        ? cloudImageUrl
        : null;
    },
    buildTheUsers(users) {
      let usersObject = [];
      if (users?.length) {
        usersObject = users.map((user) => {
          const image = (user.profile_pictures)
            ? this.getImageUrl(user.profile_pictures)
            : null;
          return {
            userId: user.user_id,
            name: user.name,
            email: user.email,
            image,
          };
        });
      }
      return usersObject;
    },
    buildTheItems(items) {
      // build the items
      const itemObjects = [];
      if (items?.length) {
        let itemId = 1;
        items.forEach((item) => {
          const element = {
            id: itemId,
            label: item.name,
            name: item.name === 'root_folder' ? item.group_name : item.name,
            description: 'to add...',
            type: item.type,
            folder_id: item.folder_id,
            project_id: item.project_id,
            page_id: item.page_id,
            group_id: item.group_id,
            group_name: item.group_name,
            path: item.slug_path,
          };
          itemId++;
          itemObjects.push(element);
        });
      }
      // sort the items after group_name and then after path
      itemObjects.sort((a, b) => {
        const textA = a.group_name.toUpperCase();
        const textB = b.group_name.toUpperCase();
        const textC = a.path.toUpperCase();
        const textD = b.path.toUpperCase();
        // eslint-disable-next-line no-nested-ternary,max-len
        return (textA < textB) ? -1 : (textA > textB) ? 1 : (textC < textD) ? -1 : (textC > textD) ? 1 : 0;
      });
      return itemObjects;
    },
    buildTheSettings(settings) {
      this.settings = settings;
      this.schedule = settings?.schedule
        || this.scheduleOptions[0].key;
      this.dropdownText = this.scheduleOptions
        .find((option) => option.key === this.schedule).value;
    },
    async editReport(name, description) {
      console.log('editing report...');
      this.report.name = name;
      this.report.description = description === '' ? null : description;
      this.openEditReportModal = false;
      await this.updateReport();
    },
    async updateReport() {
      try {
        const { name, description } = this.report;
        console.log('updating report...', this.reportId, name, description, this.settings);
        await this.$apollo.mutate({
          mutation: UpdateReport,
          variables: {
            obj: {
              reportId: this.reportId,
              name,
              description,
              settings: JSON.stringify(this.settings),
            },
          },
        });
        this.$emit('update-report', this.reportId);
      } catch (ex) {
        console.error(ex);
      }
      this.number++;
    },
    async deleteReport() {
      console.log('deleting report...');
      try {
        await this.$apollo.mutate({
          mutation: DeleteReport,
          variables: {
            obj: {
              reportId: this.report.reportId,
              name: this.report.name,
            },
          },
        });
        this.$emit('delete-report');
      } catch (ex) {
        console.error(ex);
      }
      this.openDeleteReportModal = false;
    },
    async addUser(reportUserEmail, reportUserName) {
      // add the user here
      try {
        const {
          data: {
            addReportMember: reportMember,
          },
        } = await this.$apollo.mutate({
          mutation: AddReportMember,
          variables: {
            obj: {
              reportId: this.reportId,
              email: reportUserEmail,
              fullName: reportUserName,
            },
          },
        });
        if (reportMember !== undefined) {
          if (!reportMember.user_already_in_db) {
            this.$bvToast.toast(this.$t('reports.inviteUser.newUserToastText'), {
              title: this.$t('reports.inviteUser.newUserToastTitle'),
              solid: true,
            });
          } else {
            this.$bvToast.toast(this.$t('reports.inviteUser.existingUserToastText'), {
              title: this.$t('reports.inviteUser.existingUserToastTitle'),
              solid: true,
            });
          }
        }
      } catch (ex) {
        console.error(ex);
      }
      this.openSubscribeUserModal = false;
      await this.$apollo.queries.reportDetailData.refresh();
    },
    openUnsubscribeUser(userToBeDeleted) {
      this.userToBeDeleted = userToBeDeleted;
      this.openUnsubscribeUserModal = true;
    },
    async deleteUser() {
      this.openUnsubscribeUserModal = false;
      try {
        await this.$apollo.mutate({
          mutation: DeleteReportMember,
          variables: {
            obj: {
              userId: this.userToBeDeleted.userId,
              reportId: this.reportId,
            },
          },
        });
      } catch (ex) {
        console.error(ex);
      }
      this.userToBeDeleted = null;
      await this.$apollo.queries.reportDetailData.refresh();
    },
    addItems(itemsToInsert, itemsToDelete) {
      console.log('adding new items...');
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const addDeleteItemsPromises = [];
      if (itemsToInsert.length) {
        for (let i = 0; i < itemsToInsert.length; i++) {
          const { itemId } = itemsToInsert[i];
          const { type } = itemsToInsert[i];
          let insertObject = {
            report_id: this.reportId,
            folder_id: null,
            project_id: null,
            page_id: null,
          };

          switch (type) {
            case 'group':
              insertObject.folder_id = itemsToInsert[i].rootFolderId;
              break;
            case 'folder':
              insertObject.folder_id = itemId;
              break;
            case 'project':
              insertObject.project_id = itemId;
              break;
            case 'page':
              insertObject.page_id = itemId;
              break;
            default:
              insertObject = null;
              break;
          }

          if (insertObject !== null) {
            addDeleteItemsPromises.push(this.$apollo.mutate({
              mutation: InsertReportItem,
              variables: {
                object: insertObject,
              },
            }));
          }
        }
      }
      if (itemsToDelete.length) {
        for (let i = 0; i < itemsToDelete.length; i++) {
          const item = itemsToDelete[i];
          const obj = {
            report_id: this.reportId,
            folder_id: null,
            project_id: null,
          };
          switch (item.type) {
            case 'group':
              obj.folder_id = item.rootFolderId;
              break;
            case 'folder':
              obj.folder_id = item.itemId;
              break;
            case 'project':
              obj.project_id = item.itemId;
              break;
            case 'page':
              obj.page_id = item.itemId;
              break;
            default:
              break;
          }

          addDeleteItemsPromises.push(this.$apollo.mutate({
            mutation: DeleteReportItem,
            variables: { obj },
          }));
        }
      }
      if (addDeleteItemsPromises.length > 0) {
        Promise.allSettled(addDeleteItemsPromises).then((result) => {
          console.log('all promises settled', result);
          this.$apollo.queries.reportDetailData.refresh();
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }).catch((e) => {
          console.log('error', e);
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        });
      }
    },
    showRemoveItemModal(item) {
      this.itemToBeRemoved = item;
      this.openRemoveItemModal = true;
    },
    async removeItem(item) {
      console.log('removing item...', item);
      if (item === this.itemToBeRemoved) {
        try {
          await this.$apollo.mutate({
            mutation: DeleteReportItem,
            variables: {
              obj: {
                report_id: this.reportId,
                folder_id: item.folder_id,
                project_id: item.project_id,
                page_id: item.page_id,
              },
            },
          });
          await this.$apollo.queries.reportDetailData.refresh();
        } catch (ex) {
          console.error(ex);
        }
      }
      this.itemToBeRemoved = null;
      this.openRemoveItemModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.h3 {
  display: inline-block;
}

.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        xdisplay: none;
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.alert-message {
  border: 1px solid #A05B5B;
}
</style>
