var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.report.name))
              ]),
              _vm.writeAccess
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "context-menu",
                      attrs: {
                        "toggle-class": "dot-button",
                        "no-caret": "",
                        "no-flip": "",
                        right: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "three-dots-vertical",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2014744074
                      )
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.openEditReportModal = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("dropdowns.edit")) + " ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.openDeleteReportModal = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("dropdowns.delete")) + " ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("hr")
          ]),
          _vm.openEditReportModal
            ? _c("modal-edit-report", {
                staticClass: "d-inline-block",
                attrs: { report: _vm.report },
                on: {
                  "edit-report": _vm.editReport,
                  cancel: function($event) {
                    _vm.openEditReportModal = false
                  }
                }
              })
            : _vm._e(),
          _vm.openDeleteReportModal
            ? _c("modal-delete-report", {
                staticClass: "d-inline-block",
                attrs: { report: _vm.report },
                on: {
                  "delete-report": _vm.deleteReport,
                  cancel: function($event) {
                    _vm.openDeleteReportModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.report.description))
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("reports.settings")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("label", { staticClass: "mr-2 mt-2" }, [_vm._v("Schedule:")]),
              !_vm.writeAccess
                ? _c("div", [
                    _c(
                      "span",
                      { staticStyle: { position: "relative", top: "4px" } },
                      [_vm._v(" " + _vm._s(_vm.dropdownText) + " ")]
                    )
                  ])
                : _c(
                    "div",
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            "menu-class": "w-100",
                            id: "setSchedule",
                            block: "",
                            text: _vm.dropdownText
                          }
                        },
                        _vm._l(_vm.scheduleOptions, function(option) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: option.key,
                              on: {
                                click: function($event) {
                                  return _vm.setSchedule(option)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(option.value) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.reportDescription.users")) + " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right d-flex" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group d-flex align-items-center" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "search",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _c("b-form-input", {
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: _vm.$t("webmag.search")
                        },
                        model: {
                          value: _vm.filter,
                          callback: function($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.writeAccess
                    ? _c(
                        "b-button",
                        {
                          staticClass: "green-button-transparent border-0 ml-3",
                          on: {
                            click: function($event) {
                              _vm.openSubscribeUserModal = true
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "mr-2 top--3",
                            attrs: {
                              icon: "plus-circle-fill",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("buttons.addMember")) + " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.openSubscribeUserModal
                    ? _c("modal-subscribe-user-to-report", {
                        on: {
                          "add-user": _vm.addUser,
                          cancel: function($event) {
                            _vm.openSubscribeUserModal = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _vm.users
                ? _c("b-table", {
                    ref: "userTable",
                    staticClass: "table-view users-table",
                    attrs: {
                      id: "userTable",
                      fields: _vm.userFields,
                      items: _vm.users,
                      filter: _vm.filter,
                      "sort-icon-left": "",
                      "show-empty": "",
                      borderless: "",
                      dark: ""
                    },
                    on: {
                      "sort-changed": _vm.sortChanged,
                      "context-changed": _vm.sortChanged
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2 top--3",
                                    attrs: {
                                      icon: "exclamation-circle-fill",
                                      "aria-hidden": "true",
                                      scale: "1"
                                    }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("table.noUsers")) + " "
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "emptyfiltered",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "table-center my-2" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2 top--3",
                                    attrs: {
                                      icon: "exclamation-circle-fill",
                                      "aria-hidden": "true",
                                      scale: "1"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("table.noFilteredUsers")) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "table-colgroup",
                          fn: function(scope) {
                            return _vm._l(scope.fields, function(field) {
                              return _c("col", {
                                key: field.key,
                                style: { width: field.width }
                              })
                            })
                          }
                        },
                        {
                          key: "cell(image)",
                          fn: function(data) {
                            return [
                              data.item.image !== null
                                ? _c("b-avatar", {
                                    attrs: {
                                      size: "32px",
                                      src: data.item.image
                                    }
                                  })
                                : _c("b-avatar", { attrs: { size: "32px" } })
                            ]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                          }
                        },
                        {
                          key: "cell(email)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.email) + " ")]
                          }
                        },
                        {
                          key: "cell(dot)",
                          fn: function(data) {
                            return [
                              _vm.writeAccess
                                ? _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "context-menu",
                                      attrs: {
                                        "toggle-class": "dot-button",
                                        "no-caret": "",
                                        dropup: "",
                                        "no-flip": "",
                                        right: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function() {
                                              return [
                                                _c("b-icon", {
                                                  attrs: {
                                                    icon: "three-dots-vertical",
                                                    "aria-hidden": "true",
                                                    scale: ".8"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openUnsubscribeUser(
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("dropdowns.remove")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1192084944
                    )
                  })
                : _vm._e(),
              _vm.openUnsubscribeUserModal
                ? _c("modal-unsubscribe-user-from-report", {
                    staticClass: "d-inline-block",
                    attrs: { "user-to-be-deleted": _vm.userToBeDeleted },
                    on: {
                      deleteUser: _vm.deleteUser,
                      cancel: function($event) {
                        _vm.openUnsubscribeUserModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.reportDescription.items")) + " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _vm.writeAccess && _vm.user
                    ? _c("modal-add-new-report-item", {
                        staticClass: "d-inline-block",
                        attrs: {
                          "user-id": _vm.userId,
                          "items-from-d-b": _vm.items
                        },
                        on: { addItems: _vm.addItems }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c("b-table", {
                staticClass: "table-view items-table",
                attrs: {
                  fields: _vm.itemFields,
                  items: _vm.items,
                  "sort-icon-left": "",
                  "show-empty": "",
                  borderless: "",
                  dark: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("table.noItems")) + " ")
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "emptyfiltered",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("table.noFilteredItems")) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          style: { width: field.width }
                        })
                      })
                    }
                  },
                  {
                    key: "cell(image)",
                    fn: function(data) {
                      return [
                        data.item.type === "folder"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "folder",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type === "project"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "files",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type === "page"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "file",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(group)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-group-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(
                                    data.item.group_name,
                                    20
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.group_name.length > 20
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-group-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.group_name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(path)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-path-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(data.item.path, 25)
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.path.length > 25
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-path-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.path) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-name-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(data.item.name, 23)
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.name.length > 23
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-name-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(dot)",
                    fn: function(data) {
                      return [
                        _vm.writeAccess
                          ? _c(
                              "b-dropdown",
                              {
                                staticClass: "context-menu",
                                attrs: {
                                  "toggle-class": "dot-button",
                                  "no-caret": "",
                                  dropup: "",
                                  "no-flip": "",
                                  right: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "three-dots-vertical",
                                              "aria-hidden": "true",
                                              scale: ".8"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.showRemoveItemModal(
                                          data.item
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "modals.deleteTeamItem.dropdown"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm.openRemoveItemModal
                ? _c("modal-remove-item", {
                    staticClass: "d-inline-block",
                    attrs: { item: _vm.itemToBeRemoved },
                    on: {
                      "remove-item": _vm.removeItem,
                      cancel: function($event) {
                        _vm.openRemoveItemModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.writeAccess
            ? _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
                [
                  _c("div", { staticClass: "info-box px-4 py-2 text-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("reports.notAllowedToEdit")) + " "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }